import React, { useState } from 'react';
import { getMegaMenuData } from '../../megaMenuData';
import css from './MegaMenu.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const MegaMenu = ({ lang }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const data = getMegaMenuData(lang);
  const history = useHistory();
  const isGreekLanguage = lang === 'el';
  const handleMenuItemClick = (mainUrl, key) => {
    const isSearchPage = mainUrl.startsWith('/s');
    if (isSearchPage) {
      history.push(`${mainUrl}`);
    } else {
      history.push(`${mainUrl}`);
    }
    setOpenSubMenu(null); // Close submenu when an option is clicked
  };

  return (
    <div className={css.menuContainer}>
      <div className={css.main}>
        {data.map((item, index) => {
          const { main, column = [], clickable, hoverable, image, url } = item;
          return (
            <div
              key={index}
              className={css.menuItem}
              onMouseEnter={() => setOpenSubMenu(main.label)}
            >
              {clickable ? (
                <div onClick={() => handleMenuItemClick(main.url)} className={css.label}>
                  {main.label}
                </div>
              ) : (
                <div className={css.label}>{main.label}</div>
              )}
              {hoverable && openSubMenu === main.label ? (
                <div
                  className={image?.imageUrl || image?.elImageUrl ? css.submenu : css.subMenuSingle}
                >
                  <div className={css.subMenuLeft}>
                    {/* <h4 className={css.subMenuTitle}>{main.label?.toLowerCase()}</h4> */}
                    <div className={css.columns}>
                      {column?.map((col, index) => {
                        const { title, sub } = col;

                        return (
                          <div key={index} className={css.column}>
                            <h4 className={css.subMenuTitle}>{title?.toLowerCase()}</h4>
                            {/* <h4 className={css.columnTitle}>{title}</h4> */}
                            <ul className={sub?.length > 10 ? css.ulGreater : css.ul}>
                              {sub?.map((subItem, i) => {
                                return (
                                  <li key={i} className={css.li}>
                                    <div
                                      className={css.optionLabel}
                                      onClick={() => {
                                        if (subItem?.url.startsWith('/s')) {
                                          history.push(`${subItem?.url}`);
                                        } else {
                                          history.push(`${subItem?.url}`);
                                        }

                                        setOpenSubMenu(null); // Close submenu when an option is clicked
                                      }}
                                    >
                                      {subItem.label}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {image && (
                    <div className={css.subMenuRight}>
                      <img
                        src={
                          isGreekLanguage
                            ? image?.elImageUrl
                              ? image?.elImageUrl
                              : image?.imageUrl
                            : image?.imageUrl
                        }
                        alt={main.label}
                        onClick={() => {
                          if (image?.url) handleMenuItemClick(image?.url);
                        }}
                        style={{
                          cursor: 'pointer',
                          height: image.height ? image.height : '100%',
                          width: image.width ? image.width : '100%',
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MegaMenu;
